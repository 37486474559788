import qs from "qs";
import { createAssetUrlGetter, initialiseAxios, MicroService, localApiPortNumbers, applyApiKeyHeaders, aggregatePagedResponseLists } from "@iventis/api-helpers";
import { getMap as getMapApi } from "@iventis/api-helpers-mapping";
import { Asset } from "@iventis/domain-model/model/asset";
import { Model } from "@iventis/domain-model/model/model";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { queryParams, getAssetSignature } from "@iventis/utilities";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { FilterFormat } from "@iventis/types";
import { MapEngineStyle } from "@iventis/map-engine";
import { Terrain } from "@iventis/domain-model/model/terrain";

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const getBaseUrlLocal = (microservice: MicroService) => `http://localhost:${localApiPortNumbers[microservice]}/`;

const getBaseUrl = (microservice: MicroService) => `https://${queryParams.instance}/${microservice}/`;

export const { apis, assetCacheService } = initialiseAxios(getBaseUrl, { headers: applyApiKeyHeaders(true) });

export const getMap = async (mapId: string, projectId: string) => getMapApi(mapId, projectId, apis[MicroService.MAPPING], async () => []);

export const getAsset = async (assetId: string) => apis[MicroService.ASSETS].get<Asset>(`/assets/${assetId}`).then((response) => response.data);

export const getMapTileUrl = (mapId: string) => `${getBaseUrl(MicroService.MAPPING)}map/${mapId}/tiles/{z}/{x}/{y}.pbf?ignoreZoomOptimisation=true`;

export const { assetUrlGetter, multipleAssetUrlGetter, multipleAssetGetter } = createAssetUrlGetter(apis.assets, assetCacheService);

/** Has to be here due to a circular dependency redux issue */
export const multipleModelsGetter = async (ids: string[], signal?: AbortSignal) => {
    const models = await apis[MicroService.MAPPING].get<Model[]>(`models/lods`, {
        params: { ids },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        signal,
    });
    return models.data;
};

export const getBasemapStyle = async (assetUrl: string, authoritySignature: string, name: string) => {
    const basemapResponse = await apis.cdnAssets.get<MapEngineStyle>(getAssetSignature(assetUrl, authoritySignature));
    return { name, ...basemapResponse.data };
};

export const getSitemaps = async () => {
    const filter: FilterFormat[] = [{ fieldName: "status", operator: IventisFilterOperator.In, value: ["Active"] }];
    const sitemaps = await apis[MicroService.MAPPING].get<Sitemap[]>(`/sitemaps`, {
        params: { filter: JSON.stringify(filter) },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    return sitemaps.data;
};

export const getTerrains = async () => {
    const filter: FilterFormat[] = [{ fieldName: "status", operator: IventisFilterOperator.In, value: ["Active"] }];
    const terrains = await apis[MicroService.MAPPING].get<Terrain[]>(`/terrains`, {
        params: { filter: JSON.stringify(filter) },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    return terrains.data;
};

export const getAttributeListItems = async (attributeId: string) => {
    const listItems = await aggregatePagedResponseLists<DataFieldListItem>(async (pageNumber) =>
        apis[MicroService.MAPPING]
            .get<PagedResult<DataFieldListItem[]>>(`/data-fields/${attributeId}/list-items/filter`, { params: { pageNumber } })
            .then((res) => res.data)
    );
    return listItems;
};
