/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import { mapInitialState as initialState, mapInitialState } from "@iventis/map-engine/src/defaults/map-initial-state";
import { BehaviorSubject, Subscription } from "rxjs";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { queryParams, isValidUuid, EventStream, CachedAsset, getAssetToCachedAsset, PROJECT_ID_QUERY_PARAM_KEY, API_KEY_QUERY_PARAM_KEY } from "@iventis/utilities";
import { ExportOptions } from "@iventis/map-engine/src/bridge/mapbox/engine-mapbox-types-and-constants";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { v4 as uuid } from "uuid";
import { MapStoreEventType } from "@iventis/map-engine/src/types/events-store";
import { MapState, MapboxEngineData, LayerStorageScope, MapModuleLayer, LayerDrawingControl, StoredPosition, Source } from "@iventis/map-engine/src/types/store-schema";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MAPBOX_MAX_ZOOM, MAPBOX_MIN_ZOOM } from "@iventis/map-engine/src/bridge/constants";
import { MapboxEngine } from "@iventis/map-engine/src/bridge/mapbox/engine-mapbox";
import { bustTileCache, transformRequest } from "@iventis/map-engine/src/bridge/mapbox/tile-requests";
import { EventMessageMap } from "@iventis/map-engine/src/types/map-stream.types";
import { isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { SitemapStyle } from "@iventis/map-engine/src/types/sitemap-style";
import { InvocationEventType } from "@iventis/map-engine/src/types/events-invocation";
import { mapFontStackUrl } from "@iventis/map-engine/src/map-fonts";
import { IventisErrorBoundary } from "@iventis/error-boundaries";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RestrictedCdnSignature } from "@iventis/api-helpers";
import { Terrain } from "@iventis/domain-model/model/terrain";
import {
    assetUrlGetter,
    getMapTileUrl,
    multipleAssetUrlGetter,
    multipleAssetGetter,
    multipleModelsGetter,
    getSitemaps,
    getAsset,
    getBasemapStyle,
    getMap,
    assetCacheService,
    getAttributeListItems,
    getTerrains,
} from "./api/routes";
import { AttributeThumbnailGenerator } from "./attribute-thumbnail-generator";

// Create a client
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const mapboxKey = "pk.eyJ1Ijoiam9lY3VzZGluIiwiYSI6IjFmMzMzODhmYTQ4ZWM4MmYwZGVlZDMwMTJmMDIyZWQwIn0.8taIUk7YHSD1srUfcacXBA";

export const App: React.FunctionComponent = () => {
    const onError = (code: string, error: Error) => {
        window.dispatchEvent(new CustomEvent(InvocationEventType.MAP_ERRORED, { detail: error.message }));
    };
    return (
        <IventisErrorBoundary onError={onError}>
            <MapPage />
        </IventisErrorBoundary>
    );
};

const MapPage: React.FunctionComponent = () => {
    const storeRef = useRef<BehaviorSubject<MapState<MapboxEngineData>> | null>(null);
    const eventStreamRef = useRef<EventStream<EventMessageMap> | null>(null);
    const mapElementRef = useRef<HTMLDivElement>();
    const mapEngine = useRef<MapboxEngine>();
    const [layers, setLayers] = useState<MapLayer[] | undefined>(undefined);

    const modifyStore = (change: Partial<MapState<MapboxEngineData>>) => {
        storeRef.current!.next({
            ...storeRef.current!.value,
            ...change,
        });
    };

    // Manages the rendered state of the map and the thumbnails
    const [, send] = useReducer(
        (state: { mapLoaded: boolean; dataDrivenStyleThumbnailsLoaded: boolean }, action: { type: "MAP_LOADED" | "THUMBNAILS_LOADED" }) => {
            // Once both are loaded, send the window event
            if ((action.type === "MAP_LOADED" && state.dataDrivenStyleThumbnailsLoaded) || (action.type === "THUMBNAILS_LOADED" && state.mapLoaded)) {
                window.dispatchEvent(new Event(InvocationEventType.MAP_LOADED));
            }
            if (action.type === "THUMBNAILS_LOADED") {
                return { ...state, dataDrivenStyleThumbnailsLoaded: true };
            }
            if (action.type === "MAP_LOADED") {
                return { ...state, mapLoaded: true };
            }
            return { ...state };
        },
        { mapLoaded: false, dataDrivenStyleThumbnailsLoaded: false }
    );

    useEffect(() => {
        let storeSubscription: Subscription;

        const mapId = queryParams["map-id"];
        // Get the site map versions we want to use in the export
        const siteMapQueryParam = queryParams["sitemap-version-ids"];
        const siteMapVersionIds: string[] = siteMapQueryParam ? JSON.parse(siteMapQueryParam) : [];
        const dateFilter = queryParams["date-filter"];
        const timeFilterParams = queryParams["time-filter"];
        let timeFilter = null;
        if (timeFilterParams != null) {
            // Remove the colon
            timeFilter = timeFilterParams.replace(":", "");
        }

        if (typeof mapId !== "string") {
            throw new Error("A map ID was not provided. Specify a map ID in the URL with &map-id=yourmapidhere");
        }

        let destroyed: boolean;

        getMap(mapId, queryParams["project-id"])
            .then(async ({ map, localObjects }) => {
                if (destroyed) {
                    return;
                }

                const mapBackgroundAsset = await getAsset(map.backgroundId);
                const mapBackgroundStyle = await getBasemapStyle(mapBackgroundAsset.assetUrl, mapBackgroundAsset.authoritySignature, mapBackgroundAsset.name);
                if (typeof mapBackgroundStyle.metadata === "object") {
                    mapBackgroundStyle.metadata = { ...mapBackgroundStyle.metadata, ...mapBackgroundAsset.metaData, tags: mapBackgroundAsset.tags };
                }
                const sitemaps = await getSitemaps();
                const sitemapStyles: SitemapStyle[] = [];
                const signatures: RestrictedCdnSignature[] = [];
                const level = getLevel() ?? 0;

                let terrains: Terrain[] = [];
                if (map.terrainEnabled) {
                    terrains = await getTerrains();
                    const terrainAssets = await multipleAssetGetter(terrains.map((terrain) => terrain.id));
                    terrainAssets.forEach((asset) => {
                        signatures.push({
                            url: asset.authorityUrl,
                            signature: asset.signature,
                            expiry: asset.signatureExpiry,
                            assetVersion: asset.updatedAt,
                        });
                    });
                }

                let position: StoredPosition = mapInitialState.position.value;

                if (sitemaps.length) {
                    const sitemapVersionLevels = sitemaps.flatMap((sm) =>
                        sm.versions
                            .filter((v) => v.sitemapVersionLevels.length > 0 && siteMapVersionIds.includes(v.id))
                            .map((smv) => {
                                const sitemapVersionLevel = smv.sitemapVersionLevels.find((mapLevel) => mapLevel.levelIndex === level);
                                const sitemapVersionLevelId = sitemapVersionLevel?.id ?? smv.sitemapVersionLevels[0]?.id;
                                return { id: sitemapVersionLevelId, perimeter: sitemapVersionLevel?.perimeter };
                            })
                    );

                    if (sitemapVersionLevels?.length > 0) {
                        const sitemapAssets = await multipleAssetGetter(sitemapVersionLevels.map((smv) => smv.id));

                        // eslint-disable-next-line no-restricted-syntax
                        for (const [index, sitemapVersionLevel] of Object.entries(sitemapVersionLevels)) {
                            const asset: CachedAsset = sitemapAssets[index];
                            const style = await getBasemapStyle(asset.url, asset.signature, asset.name);
                            sitemapStyles.push({ style, sitemapVersionLevelId: sitemapVersionLevel.id, perimeter: sitemapVersionLevel.perimeter });

                            signatures.push({
                                url: asset.authorityUrl,
                                signature: asset.signature,
                                expiry: asset.signatureExpiry,
                                assetVersion: asset.updatedAt,
                            });
                        }
                    }
                }

                const exportOptions = getMapExportOptions();

                if (map.defaultSavedMapViewId) {
                    const { position: savedMapViewPosition } = map.savedMapViews.find((smv) => smv.id === map.defaultSavedMapViewId);
                    position = {
                        lng: savedMapViewPosition.longitude,
                        lat: savedMapViewPosition.latitude,
                        bearing: savedMapViewPosition.bearing,
                        zoom: savedMapViewPosition.zoom,
                        pitch: savedMapViewPosition.pitch,
                        source: Source.HOST,
                    };
                }

                if (mapElementRef.current !== undefined) {
                    storeRef.current = new BehaviorSubject({
                        ...initialState,
                        layers: { value: map.layers.map((l) => parseLayerToIventisLayer(l)), stamp: uuid() },
                        geoJSON: {
                            value: localObjects,
                            stamp: uuid(),
                        },
                        tileSources: {
                            value: {
                                objects: {
                                    tiles: [
                                        {
                                            name: "iventis",
                                            tiles: [getMapTileUrl(map.id)],
                                            type: "vector",
                                        },
                                    ],
                                },
                            },
                            stamp: uuid(),
                        },
                        position: {
                            value: position,
                            stamp: uuid(),
                        },
                        engineSpecificData: {
                            styles: {
                                value: {
                                    [AssetType.MapBackground]: mapBackgroundStyle,
                                    [AssetType.SiteMap]: sitemapStyles,
                                },
                                stamp: uuid(),
                            },
                        },
                        currentLevel: level,
                        datesFilter: {
                            value: {
                                filter: timeFilter != null && dateFilter != null,
                                time: timeFilter != null ? parseInt(timeFilter, 10) : null,
                                day: dateFilter != null ? parseInt(dateFilter, 10) : null,
                            },
                            stamp: uuid(),
                        },
                        streetNames: {
                            value: map.streetNamesEnabled,
                            stamp: uuid(),
                        },
                        buildings3D: {
                            value: map.buildings3DEnabled,
                            stamp: uuid(),
                        },
                        terrain3D: {
                            value: {
                                enabled: map.terrainEnabled,
                                exaggeration: 1,
                                customTerrains: terrains,
                            },
                            stamp: uuid(),
                        },
                    } as MapState<MapboxEngineData>);

                    eventStreamRef.current = new EventStream<EventMessageMap>();
                    const engine = new MapboxEngine({
                        store: { change: storeRef.current },
                        container: mapElementRef.current,
                        eventStream: eventStreamRef.current,
                        mbxAccessToken: mapboxKey,
                        preview: false,
                        modifierKeys: ["Control", "Meta"],
                        preserveDrawingBuffer: true,
                        assetOptions: {
                            // Route only accepts uuids, but some basemap ids may pass into here so we must filter them out
                            assetUrlGetter: async (id) => (isValidUuid(id) ? assetUrlGetter(id) : null),
                            multipleAssetUrlGetter: async (ids) => multipleAssetUrlGetter(ids),
                            bustCacheIds: () => null,
                            multipleModelsGetter,
                            isAssetSdf: async (id) => (await assetCacheService.get(id, async () => getAssetToCachedAsset(id, getAsset)))?.metaData?.sdf ?? false,
                        },
                        bustTileCache,
                        transformRequest: (url) =>
                            transformRequest(
                                url,
                                signatures,
                                queryParams[API_KEY_QUERY_PARAM_KEY],
                                storeRef.current.getValue().terrain3D.value.customTerrains,
                                queryParams[PROJECT_ID_QUERY_PARAM_KEY]
                            ),
                        maxZoom: MAPBOX_MAX_ZOOM,
                        minZoom: MAPBOX_MIN_ZOOM,
                        fontStackUrl: mapFontStackUrl,
                        exportOptions,
                        getAttributeListItems,
                        routeApiFunctions: undefined,
                        isExternalUser: false,
                        user: { id: "", isMobileUser: false },
                    });

                    mapEngine.current = engine;

                    storeSubscription = engine.storeEvents.subscribe((event) => {
                        switch (event.eventType) {
                            case MapStoreEventType.SET_MODE:
                                modifyStore({
                                    mode: {
                                        value: event.payload.mode,
                                        stamp: uuid(),
                                    },
                                });
                                break;
                            default:
                                // eslint-disable-next-line no-console
                                console.warn(`Map store event not handled '${event.eventType}'`);
                                break;
                        }
                    });

                    engine.invocationEvents.subscribe((e) => {
                        if (e.type === InvocationEventType.MAP_LOADED) {
                            send({ type: "MAP_LOADED" });
                        }
                    });
                }
                setLayers(map.layers);
            })
            .catch((error) => {
                console.error(error);
                // Send error to map renderer to finish export process early
                window.dispatchEvent(new CustomEvent(InvocationEventType.MAP_ERRORED, { detail: error.message }));
            });

        return () => {
            destroyed = true;
            mapEngine.current?.destroy();
            storeSubscription?.unsubscribe();
            storeRef.current?.unsubscribe();
            eventStreamRef.current?.destroy();
        };
    }, [mapElementRef.current]);

    return (
        <QueryClientProvider client={queryClient}>
            <div ref={mapElementRef as React.MutableRefObject<HTMLDivElement>} style={{ height: "100%", width: "100%" }} />
            <AttributeThumbnailGenerator layers={layers} onThumbnailsLoaded={() => send({ type: "THUMBNAILS_LOADED" })} />
        </QueryClientProvider>
    );
};

/**
 * Assumes any inputted layer is remote
 * @param layer
 * @returns
 */
export function parseLayerToIventisLayer(layer: MapLayer, remote = true): MapModuleLayer {
    return {
        ...layer,
        storageScope: isModelLayer(layer) ? LayerStorageScope.LocalOnly : LayerStorageScope.LocalAndTiles,
        source: isModelLayer(layer) ? layer.id : "iventis",
        stamp: "",
        selected: false,
        remote,
        drawingControls: {
            [LayerDrawingControl.COORDINATE_HANDLE]: false,
            [LayerDrawingControl.MID_POINT_HANDLE]: false,
            [LayerDrawingControl.ROTATION_HANDLE]: false,
        },
    };
}

export function getMapExportOptions(): ExportOptions | undefined {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamBounds = urlParams.get("map-bounds");
    const urlParamBearing = urlParams.get("bearing");
    const urlParamScale = urlParams.get("scale");
    const urlParamPitch = urlParams.get("pitch");
    if (urlParamBounds != null && urlParamBearing != null && urlParamPitch != null) {
        return {
            bounds: JSON.parse(urlParamBounds),
            bearing: Number(urlParamBearing),
            scale: urlParamScale != null ? Number(urlParamScale) : undefined,
            pitch: Number(urlParamPitch),
        };
    }
    return undefined;
}

export function getLevel(): number {
    const urlParams = new URLSearchParams(window.location.search);
    const level = Number(urlParams.get("level"));
    if (Number.isNaN(level) || level == null) {
        return null;
    }
    return level;
}
